<template>
    <div class="product-inner">
        <div class="thumb has-second-image">
            <router-link :to="{ name: 'good', params: { good_id:id} }">
                <n-image
                    v-if="mainImage"
                    lazy
                    width="100%"
                    preview-disabled
                    :src="mainImage"
                />
                <n-image
                    v-else
                    lazy
                    width="100%"
                    preview-disabled
                    :src="PreviewImage"
                />
            </router-link> 
            <div class="group-button">
                <button @click="addDelWishlistItem" class="wishlist margin-5px">
                    <div v-if="wishlistAddLoading" class="as-spinner-loader"></div>
                    <span 
                        v-else 
                        class="wishlist"
                        :class="{ 'in-wishlist': itemInWishlist(id)}"
                    ></span>
                </button>
                <button @click="addToCart" class="button add_to_cart_button margin-5px">
                    <div v-if="cartAddloading" class="as-spinner-loader"></div>
                    <span v-else class="add_to_cart_button"></span>
                </button>
            </div>
        </div>
        <div class="info">
            <h3 class="product-name short">
                <router-link 
                    data-toggle="tooltip" data-placement="top" :title="name"
                    :to="{ name: 'good', params: { good_id:id} }"
                >
                    {{ name }}
                </router-link>
            </h3>
            <div v-if="userToken && !show_price_by_liter" class="product-footer">
                <span class="price">Цена:</span>
                <span class="price">{{ price }} &#8381;</span>
            </div>
            <div v-if="userToken && show_price_by_liter" class="product-footer">
                <span class="price">Цена за литр:</span>
                <span class="price">{{ price_by_liter }} &#8381;</span>
            </div>
            <div v-if="userToken" class="product-footer">
                <span class="price">Остаток:</span>
                <span class="price">{{ balance }} шт.</span>
            </div>
        </div>
    </div>
</template>

<script>

import PreviewImage from "@/assets/images/products/preview.png"
import {backendPath} from "@/main.js"

export default {
    name: 'GoodComponent',
    props: ['goodInfo'],
    data () {
        return {
            PreviewImage,
            id: this.goodInfo.id,
            name: this.goodInfo.full_name,
            art: this.goodInfo.art,
            price: this.goodInfo.price,
            balance: Math.floor(this.goodInfo.balance),
            show_price_by_liter: this.goodInfo.show_price_by_liter,
            price_by_liter: this.goodInfo.price_by_liter,
            cartAddloading: false,
            wishlistAddLoading: false
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        mainImage () {
            let path = ''
            if (this.goodInfo.images.length > 0) {
                path = backendPath + this.goodInfo.images[0].image.url
            }
            return path
        },
        qtyInCart () {
            let cartItemById = this.$store.getters.cartItemById(this.id)
            let qtyInCart = 0
            if (cartItemById) {
                qtyInCart = cartItemById.quantity
            }
            return qtyInCart
        },
    },
    methods: {
        itemInWishlist (id) {
            if (this.userToken !='') {
                return this.$store.getters.wishlistItemById(id)
            }
            return null
        },
        addDelWishlistItem () {
            if (this.userToken != '' && this.wishlistAddLoading != true) {
                this.wishlistAddLoading = true
                new Promise(resolve => {
                    if (this.itemInWishlist(this.goodInfo.id)) {
                        this.$store.dispatch('addDelWishlistItem', {good_id: this.goodInfo.id, authToken: this.userToken, action: 'del'})
                    } else {
                        this.$store.dispatch('addDelWishlistItem', {good_id: this.goodInfo.id, authToken: this.userToken, action: 'add'})
                    }
                    resolve("")
                }).then(() => {
                    setTimeout(() => {
                        this.wishlistAddLoading = false
                    }, 500);
                })
                
            }
        },
        addToCart () {
            if (this.userToken != '') {
                this.cartAddloading = true
                this.$store.dispatch('addDelCartItem', 
                {
                    good_id: this.goodInfo.id,
                    authToken: this.userToken,
                    quantity: 1,
                    action: 'add'
                }).then(() => {
                    setTimeout(() => {
                        this.cartAddloading = false
                    }, 500);
                })
            }
        },
    }
}
</script>

<style>
.wishlist.margin-5px {
    margin: 5px !important;
}
.product-footer {
    display: flex;
    justify-content: space-between;
}
.as-spinner-loader{
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}
.as-spinner-loader:before, .as-spinner-loader:after{
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color: #aaaaaa;
}
.as-spinner-loader:before{
  z-index: 100;
  animation: spin 1s infinite;
}
.as-spinner-loader:after{
  border: 3px solid #ccc;
}
@keyframes spin{
  0%{
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>