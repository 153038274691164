<template>
    <div></div>
</template>

<script>
    import { useMessage } from 'naive-ui'
    import { defineComponent } from 'vue'

    export default defineComponent({
        setup() {
            const message = useMessage()
            const success = (text) => {
                message.success(
                    `${text}`,
                    {
                        keepAliveOnHover: true
                    }
                )
            }
            const info = (text) => {
                message.info(
                    `${text}`,
                    {
                        keepAliveOnHover: true
                    }
                )
            }
            const error = (text) => {
                message.error(
                    `${text}`,
                    {
                        keepAliveOnHover: true
                    }
                )
            }
            
            return {
                success,
                info,
                error
            }
        },
        computed: {
            addCartItemMessage () {
                return this.$store.getters.addCartItemMessage
            },
            delCartItemMessage () {
                return this.$store.getters.delCartItemMessage
            },
            addWishlistItemMessage () {
                return this.$store.getters.addWishlistItemMessage
            },
            delWishlistItemMessage () {
                return this.$store.getters.delWishlistItemMessage
            },
        },
        watch: {
            addCartItemMessage: {
                deep: true,
                handler() {
                    if (this.addCartItemMessage != "") {
                        this.success(this.addCartItemMessage)
                    }
                }
            },
            delCartItemMessage: {
                deep: true,
                handler() {
                    if (this.delCartItemMessage != "") {
                        this.success(this.delCartItemMessage)
                    }
                }
            },
            addWishlistItemMessage: {
                deep: true,
                handler() {
                    if (this.addWishlistItemMessage != "") {
                        this.success(this.addWishlistItemMessage)
                    }
                }
            },
            delWishlistItemMessage: {
                deep: true,
                handler() {
                    if (this.delWishlistItemMessage != "") {
                        this.success(this.delWishlistItemMessage)
                    }
                }
            },
        }
    })

</script>